<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="16">
        <a-spin :spinning="processing">
          <a-table
            bordered
            v-if="exercices.length"
            :dataSource="exercices"
            :columns="columns"
            :rowKey="
              (record) => {
                return record.id;
              }
            "
          >
            <template slot="row_index" slot-scope="text, record, index">
              <p>{{ index + 1 }}</p>
            </template>

            <template slot="courant" slot-scope="text, record">
              <a-tooltip>
                <a-icon
                  v-if="record.is_current === EXERCICE_COURANT"
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                  style=" font-size: large;"
                />
                <template slot="title">Année courante</template>
              </a-tooltip>
            </template>
            <template slot="annee" slot-scope="text, record">
              {{ record.annee | year }}
            </template>
            <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
              <a-table
                bordered
                :pagination="false"
                :dataSource="record.type_reglements"
                :columns="child_row_columns"
                :rowKey="
                  (record) => {
                    return record.id;
                  }
                "
              >
                <template slot="row_index" slot-scope="text, record, index">
                  <p>{{ index + 1 }}</p>
                </template>
                <template slot="type_cotisation" slot-scope="text, record">
                  {{ record.nom_fr }}
                </template>
                <template slot="code" slot-scope="text, record">
                  {{ record.code }}
                </template>
                <template slot="montant" slot-scope="text, record">
                  {{ get(record, "montant") | currency }}
                </template>
              </a-table>
            </div>
          </a-table>
          <EmptyResult v-else />
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import EmptyResult from "@/components/common/EmptyResult";

  const EXERCICE_COURANT = 1;
  export default {
    name: "ExercicesList",
    created() {},
    components: {
      EmptyResult,
    },
    data() {
      return {
        columns: [
          {
          title: "N°",
          scopedSlots: { customRender: "row_index" },
        },
        {
          title: "Année",
          scopedSlots: { customRender: "annee" },
        },
        {
          title: "Etat",
          scopedSlots: { customRender: "courant" },
        },
      ],
      child_row_columns: [
        {
          title: "N°",
          scopedSlots: { customRender: "row_index" },
        },
        {
          title: "Code",
          scopedSlots: { customRender: "code" },
        },
        {
          title: "Type cotisation",
          scopedSlots: { customRender: "type_cotisation" },
        },
        {
          title: "Montant",
          scopedSlots: { customRender: "montant" },
        },
      ],
      EXERCICE_COURANT,
    };
  },
  props: ["exercices", "processing"],
};
</script>