var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-empty", { attrs: { image: _vm.img_url } }, [
        _c("span", { attrs: { slot: "description" }, slot: "description" }, [
          _vm._v("\n      Aucune donnée.\n    ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }