<template>
  <div>
    <a-empty :image="img_url">
      <span slot="description">
        Aucune donnée.
      </span>
    </a-empty>
  </div>
</template>
<script>
import image from "../../assets/empty-result.png"
export default {
  name: "EmptyResult",
  data (){
    return {
        img_url: image
    }
  }
};
</script>
