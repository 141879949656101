<template>
    <div>
        <a-row type="flex" justify="center">
            <a-col :span="14">
                <h1>Exercices</h1>
            </a-col>
        </a-row>
        <a-row>
            <a-col>
                <ExercicesList :exercices="dataSource" :processing="processing"></ExercicesList>
            </a-col>
        </a-row>
    </div>
</template>
<script>
    // import {mapState, mapActions, mapGetters} from "vuex";
    import {mapState, mapActions} from "vuex";
    import ExercicesList from "./ExcercicesList";

    export default {
        name: "ExercicesIndex",
        created() {
            this.fetchData();
        },
        components: {
            ExercicesList
        },
        data() {
            return {
                processing: true
            };
        },
        computed: {
            ...mapState({
                dataSource: state => state.exercices.exercices
            })
        },
        methods: {
            fetchData() {
                this.getAdditioanlData()
                    .then(() => (this.processing = false))
                    .catch(() => (this.processing = false));
            },
            onCellChange(id, dataIndex, $event) {
                const attestation = this.dataSource.find(item => item.id === id);
                if (attestation) {
                    attestation.name = $event;
                    this.update(attestation)
                        .then(() => this.fetchData());
                }
            },
            ...mapActions({
                getAdditioanlData: "fetchAdminExecrcices",
                update: "updateAdminExecrcices"
            })
        }
    };
</script>
<style>
    .editable-cell {
        position: relative;
    }

    .editable-cell-input-wrapper,
    .editable-cell-text-wrapper {
        padding-right: 24px;
    }

    .editable-cell-text-wrapper {
        padding: 5px 24px 5px 5px;
    }

    .editable-cell-icon,
    .editable-cell-icon-check {
        position: absolute;
        right: 0;
        width: 20px;
        cursor: pointer;
    }

    .editable-cell-icon {
        line-height: 18px;
       
    }

    .editable-cell-icon-check {
        line-height: 28px;
    }

    .editable-cell:hover .editable-cell-icon {
        display: inline-block;
    }

    .editable-cell-icon:hover,
    .editable-cell-icon-check:hover {
        color: #108ee9;
    }

    .editable-add-btn {
        margin-bottom: 8px;
    }

    .decission_btn {
        text-decoration: underline;
    }
</style>
